var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("search-list")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center uppercase pb-4"
  }, [_vm._v(_vm._s(_vm.$t("testator-confirmation-list")))]), _vm._m(0), _vm.wasiats == null ? _c('div', {
    staticClass: "p-5 text-center"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("no-records-found")))])]) : _vm._e(), _vm.wasiats ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('th', [_vm._v(_vm._s(_vm.$t("organization")))]), _c('th', [_vm._v(_vm._s(_vm.$t("agent")))]), _c('th', [_vm._v(_vm._s(_vm.$t("register-date")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("action")))]), _vm.organization.is_bmmb ? _c('th', [_vm._v(_vm._s(_vm.$t("loan-status")))]) : _vm._e()])]), _c('tbody', [_vm._l(_vm.wasiats, function (wasiat, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(wasiat.user ? wasiat.user.name : "-"))]), _c('td', [_vm._v(" " + _vm._s(wasiat.user ? wasiat.user.ic_number : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(wasiat.organization ? wasiat.organization.name : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(wasiat.agent ? wasiat.agent.name : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("getConvertDate")(wasiat ? wasiat.recorded_wasiat_date : "-")) + " ")]), _c('td', [_c('button', {
      staticClass: "btn-sm btn-primary",
      on: {
        "click": function ($event) {
          return _vm.pengesahan(wasiat.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("verification")) + " ")])]), _vm.organization.is_bmmb ? _c('td', [[0, null].includes(wasiat.bmmb_loan_status) ? _c('span', {
      staticClass: "badge badge-warning"
    }, [_vm._v(_vm._s(_vm.$t("in-process")))]) : _vm._e(), wasiat.bmmb_loan_status == 1 ? _c('span', {
      staticClass: "badge badge-success"
    }, [_vm._v(_vm._s(_vm.$t("passed")))]) : _vm._e(), wasiat.bmmb_loan_status == 2 ? _c('span', {
      staticClass: "badge badge-danger"
    }, [_vm._v(_vm._s(_vm.$t("fail")))]) : _vm._e()]) : _vm._e()]);
  }), _vm.wasiats.length == 0 ? _c('tr', [_c('td', {
    staticClass: "p-5 text-center",
    attrs: {
      "colspan": "7"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("no-records-found")))])])]) : _vm._e()], 2)])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v("25 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))])])])])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0 card border-0 sidebar sticky-bar"
  })]);
}]

export { render, staticRenderFns }